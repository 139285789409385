<template>
  <div class="body-wrapper">
    <Headernav />

    <div class="w-full">
      <!-- <div class="w-full contact-bg" style="height:150px;"></div> -->
      <no-ssr> <!-- important to add no-ssr-->
        <carousel
          class="w-full" style="background-color: #001e81 !important;" :autoHeight="true" :autoplay="true" :autoplayTimeout="5000" :dots="flase" :loop="true" :nav="false" :rewind="true" :responsive="{
            0: {
              items: 1,
              margin: 0
            },
            768: {
              items: 1,
              margin: 0
            }
          }"
          >
          <div class="header-banner-wrapper01 banner-height flex items-end">
            <div class="container mb-12">
              <div class="w-full ,b-8 md:mb-12">
                <div class="w-full hidden md:block"></div>
                <div class="w-full lg:w-8/12 text-left">
                  <div class="header-banner-title text-white mb-3">
                    <p class="header-banner-sub-title pb-2">Trusted</p>
                    <p class="pb-3">Supplier and </p>
                    <p class="pb-2">Distributor</p>
                    <p class="header-banner-sub-title">Throughout The Philippines!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="header-banner-wrapper02 banner-height flex items-end">
            <div class="container mb-12">
              <div class="w-full mb-8 md:mb-12">
                <div class="w-full hidden md:block"></div>
                <div class="w-full lg:w-8/12 text-left">
                   <div class="header-banner-title text-white mb-3">Our Clientele</div>
                </div>
              </div>
            </div>
          </div>

          <div class="header-banner-wrapper03 banner-height flex items-end">
            <div class="container mb-12">
              <div class="w-full mb-8 md:mb-12">
                <div class="w-full hidden md:block"></div>
                <div class="w-full lg:w-8/12 text-left">
                  <div class="header-banner-title text-white mb-3">
                    <!-- <p class="header-banner-sub-title pb-2">Trusted</p> -->
                    <p class="text-2xl md:text-6xl pb-2 md:pb-3">We do interior fit out and trading services</p>
                    <!-- <p class="text-2xl md:text-5xl pb-2 md:pb-3"></p> -->
                    <!-- <p class="header-banner-sub-title">brought straight to the comfort of your home or office</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

        </carousel>
      </no-ssr>
      <div id="contact" class=""></div>
    </div>

    <div  class="w-full common-bg-blue02">
      <div class="w-full lg:flex">
        <div class="lg:w-6/12 common-bg-blue py-12 px-6 md:p-12 m-auto">
          <div class="py-3 lg:py-6"></div>
          <div class="w-full section-title text-white pb-6">
            Inquire Now!
          </div>
          <div class="w-full">
            <contactform />
          </div>
          <div class="py-3 lg:py-6"></div>
        </div>
        <div class="lg:w-6/12">
          <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15446.125229722242!2d121.0849779!3d14.5687743!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3b1c474e4d6ca1cc!2sFederal%20Concepts%20Inc.!5e0!3m2!1sen!2sph!4v1581324711988!5m2!1sen!2sph"
          width="100%"
          height="100%"
          frameborder="0"
          style="border:0;"
          allowfullscreen="">
          </iframe>
        </div>
      </div>
      <div id="products" class=""></div>
    </div>
    
    <div class="w-full common-bg-blue02 section">
      <div class="container">
        <div class="w-full py-6">
          <div class="w-full text-center section-title text-white mb-12">
            FEATURED PRODUCTS
            <!-- <div class="yellow-border m-auto"></div> -->
          </div>
          <div class="w-full md:flex md:flex-wrap">
            <div class="w-full md:w-1/3 md:pl-4 md:pr-4 mb-8">
              <div class="w-full pl-6 pr-6 md:pl-0 md:pr-0 lg:pl-6 lg:pr-6">
                <img class="rounded-md mb-5" src="/images/products-blinds.jpg">
              </div>
              <div class="w-full text-center sub-title text-white">Window Blinds &<br>Wallpapers</div>
            </div>
            <div class="w-full md:w-1/3 md:pl-4 md:pr-4 mb-8">
              <div class="w-full pl-6 pr-6 md:pl-0 md:pr-0 lg:pl-6 lg:pr-6">
               <img class="rounded-md mb-5" src="/images/products-partitions.jpg">
              </div>
              <div class="w-full text-center sub-title text-white">Office System Partition<br>& Chairs</div>
            </div>
            <div class="w-full md:w-1/3 md:pl-4 md:pr-4 mb-">
              <div class="w-full pl-6 pr-6 md:pl-0 md:pr-0 lg:pl-6 lg:pr-6">
                <img class="rounded-md mb-5" src="/images/products-furniture.jpg">
              </div>
              <div class="w-full text-center sub-title text-white">Customized Furniture</div>
            </div>
            <div class="w-full md:w-1/3 md:pl-4 md:pr-4 mb-8">
              <div class="w-full pl-6 pr-6 md:pl-0 md:pr-0 lg:pl-6 lg:pr-6">
                <img class="rounded-md mb-5" src="/images/products-construction.jpg">
              </div>
              <div class="w-full text-center sub-title text-white">Interior Construction<br>Services</div>
            </div>


            <div class="w-full md:w-1/3 md:pl-4 md:pr-4 mb-8">
              <div class="w-full pl-6 pr-6 md:pl-0 md:pr-0 lg:pl-6 lg:pr-6">
                <img class="rounded-md mb-5" src="/images/new-product-img/product-wall-carpet.jpg">
              </div>
              <div class="w-full text-center sub-title text-white">Wall Carpet for Soundproofing</div>
            </div>
            <div class="w-full md:w-1/3 md:pl-4 md:pr-4 mb-8">
              <div class="w-full pl-6 pr-6 md:pl-0 md:pr-0 lg:pl-6 lg:pr-6">
                <img class="rounded-md mb-5" src="/images/new-product-img/product-carpettile-supply-installation.jpg">
              </div>
              <div class="w-full text-center sub-title text-white">Carpet Tile Supply and Installation</div>
            </div>
            <div class="w-full md:w-1/3 md:pl-4 md:pr-4 mb-8">
              <div class="w-full pl-6 pr-6 md:pl-0 md:pr-0 lg:pl-6 lg:pr-6">
                <img class="rounded-md mb-5" src="/images/new-product-img/product-glasswall.jpg">
              </div>
              <div class="w-full text-center sub-title text-white">Glasswall and Customized Conference Table</div>
            </div>
            <div class="w-full md:w-1/3 md:pl-4 md:pr-4 mb-8">
              <div class="w-full pl-6 pr-6 md:pl-0 md:pr-0 lg:pl-6 lg:pr-6">
                <img class="rounded-md mb-5" src="/images/new-product-img/product-vinyl-planks.jpg">
              </div>
              <div class="w-full text-center sub-title text-white">Vinyl Planks Supply and Installation</div>
            </div>
            <div class="w-full md:w-1/3 md:pl-4 md:pr-4 mb-8">
              <div class="w-full pl-6 pr-6 md:pl-0 md:pr-0 lg:pl-6 lg:pr-6">
                <img class="rounded-md mb-5" src="/images/new-product-img/product-executive-table.jpg">
              </div>
              <div class="w-full text-center sub-title text-white">Custom Executive Table</div>
            </div>
            <div class="w-full md:w-1/3 md:pl-4 md:pr-4 mb-8">
              <div class="w-full pl-6 pr-6 md:pl-0 md:pr-0 lg:pl-6 lg:pr-6">
                <img class="rounded-md mb-5" src="/images/new-product-img/product-carpet-roll.jpg">
              </div>
              <div class="w-full text-center sub-title text-white">Carpet Roll / Wall to Wall Carpet</div>
            </div>

            <div class="w-full md:w-1/3 md:pl-4 md:pr-4 mb-8">
              <div class="w-full pl-6 pr-6 md:pl-0 md:pr-0 lg:pl-6 lg:pr-6">
                <img class="rounded-md mb-5" src="/images/new-product-img/product-electrical-installation.jpg">
              </div>
              <div class="w-full text-center sub-title text-white">Electrical and Data Installation</div>
            </div>
            <div class="w-full md:w-1/3 md:pl-4 md:pr-4 mb-8">
              <div class="w-full pl-6 pr-6 md:pl-0 md:pr-0 lg:pl-6 lg:pr-6">
                <img class="rounded-md mb-5" src="/images/new-product-img/product-modular.jpg">
              </div>
              <div class="w-full text-center sub-title text-white">Floor to Ceiling Modular Partition</div>
            </div>

          </div>
        </div>
        <div id="projects" class=""></div>
      </div>
    </div>

    <div class="w-full common-bg-blue">
      <div class="w-full flex flex-wrap">

        <div class="w-6/12 md:w-3/12 ml-0 mr-0 pl-0 pr-0">
          <img id="modal01" class="w-full modalOpenBtn border-b-4 border-r-4 border-white" onclick="openModal('modal01')" src="/images/federal-projects/01-afp/img03.jpg" alt="Federal Concept Projects">
          <div id="modal01" class="modal px-3">
            <div class="modalContent px-6 py-6 md:px-8 md:py-8">
              <span class="modalClose">×</span>
              <div class="sub-title text-blue-700 pb-6">
                AFP ETDC COMMAND CENTER
              </div>
              <div class="w-full flex flex-wrap">
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/01-afp/img03.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/01-afp/img05.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/01-afp/img04.jpg" alt="Federal Concept Projects">
                </div>
                 <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/01-afp/img01.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/01-afp/img02.jpg" alt="Federal Concept Projects">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-6/12 md:w-3/12 ml-0 mr-0 pl-0 pr-0">
          <img id="modal02" class="w-full border-b-4 border-r-4 border-white" onclick="openModal('modal02')" src="/images/federal-projects/02-soil-personel/img01.jpg" alt="Federal Concept Projects">
          <div id="modal02" class="modal px-3">
            <div class="modalContent px-6 py-6 md:px-8 md:py-8">
              <span class="modalClose">×</span>
              <div class="sub-title text-blue-700 pb-6">
                BUREAU OF SOIL PERSONEL OFFICE
              </div>
              <div class="w-full flex flex-wrap">
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/02-soil-personel/img01.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/02-soil-personel/img02.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/02-soil-personel/img03.jpg" alt="Federal Concept Projects">
                </div>
                 <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/02-soil-personel/img04.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/02-soil-personel/img05.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/02-soil-personel/img06.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/02-soil-personel/img07.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/02-soil-personel/img08.jpg" alt="Federal Concept Projects">
                </div>
                 <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/02-soil-personel/img09.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/02-soil-personel/img10.jpg" alt="Federal Concept Projects">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-6/12 md:w-3/12 ml-0 mr-0 pl-0 pr-0">
          <img id="modal03" class="w-full border-b-4 border-r-4 border-white" onclick="openModal('modal03')" src="/images/federal-projects/03-da-bureau-soil/img01.jpg" alt="Federal Concept Projects">
          <div id="modal03" class="modal px-3">
            <div class="modalContent px-6 py-6 md:px-8 md:py-8">
              <span class="modalClose">×</span>
              <div class="sub-title text-blue-700 pb-6">
                DA BUREAU OF SOIL
              </div>
              <div class="w-full flex flex-wrap">
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/03-da-bureau-soil/img01.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/03-da-bureau-soil/img02.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/03-da-bureau-soil/img03.jpg" alt="Federal Concept Projects">
                </div>
                 <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/03-da-bureau-soil/img04.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/03-da-bureau-soil/img05.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/03-da-bureau-soil/img06.jpg" alt="Federal Concept Projects">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-6/12 md:w-3/12 ml-0 mr-0 pl-0 pr-0">
          <img id="modal04" class="w-full border-b-4 border-r-4 border-white" onclick="openModal('modal04')" src="/images/federal-projects/04-deped/img01.jpg" alt="Federal Concept Projects">
          <div id="modal04" class="modal px-3">
            <div class="modalContent px-6 py-6 md:px-8 md:py-8">
              <span class="modalClose">×</span>
              <div class="sub-title text-blue-700 pb-6">
                DEPED TAYABAS DIVISION OFFICE
              </div>
              <div class="w-full flex flex-wrap">
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/04-deped/img01.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/04-deped/img02.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/04-deped/img03.jpg" alt="Federal Concept Projects">
                </div>
                 <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/04-deped/img04.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/04-deped/img05.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/04-deped/img06.jpg" alt="Federal Concept Projects">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-6/12 md:w-3/12 ml-0 mr-0 pl-0 pr-0">
          <img id="modal05" class="w-full border-b-4 border-r-4 border-white" onclick="openModal('modal05')" src="/images/federal-projects/05-dpwh/img05.jpg" alt="Federal Concept Projects">
          <div id="modal05" class="modal px-3">
            <div class="modalContent px-6 py-6 md:px-8 md:py-8">
              <span class="modalClose">×</span>
              <div class="sub-title text-blue-700 pb-6">
                DPWH - TOYO CONSTRUCTION SATELITE OFFICE
              </div>
              <div class="w-full flex flex-wrap">
                <div class="w-full md:w-6/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/05-dpwh/img01.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-6/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/05-dpwh/img02.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-6/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/05-dpwh/img03.jpg" alt="Federal Concept Projects">
                </div>
                 <div class="w-full md:w-6/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/05-dpwh/img04.jpg" alt="Federal Concept Projects">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-6/12 md:w-3/12 ml-0 mr-0 pl-0 pr-0">
          <img id="modal06" class="w-full border-b-4 border-r-4 border-white" onclick="openModal('modal06')" src="/images/federal-projects/06-goldilocks/img01.jpg" alt="Federal Concept Projects">
          <div id="modal06" class="modal px-3">
            <div class="modalContent px-6 py-6 md:px-8 md:py-8">
              <span class="modalClose">×</span>
              <div class="sub-title text-blue-700 pb-6">
                GOLDILOCKS SHAW BLVD
              </div>
              <div class="w-full flex flex-wrap">
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/06-goldilocks/img01.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/06-goldilocks/img02.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/06-goldilocks/img03.jpg" alt="Federal Concept Projects">
                </div>
                 <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/06-goldilocks/img04.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/06-goldilocks/img05.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/06-goldilocks/img06.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/06-goldilocks/img07.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/06-goldilocks/img08.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/06-goldilocks/img09.jpg" alt="Federal Concept Projects">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-6/12 md:w-3/12 ml-0 mr-0 pl-0 pr-0">
          <img id="modal07" class="w-full border-b-4 border-r-4 border-white" onclick="openModal('modal07')" src="/images/federal-projects/07-megaworld/img01.jpg" alt="Federal Concept Projects">
          <div id="modal07" class="modal px-3">
            <div class="modalContent px-6 py-6 md:px-8 md:py-8">
              <span class="modalClose">×</span>
              <div class="sub-title text-blue-700 pb-6">
                MEGAWORLD ADMIN OFFICE PARANAQUE
              </div>
              <div class="w-full flex flex-wrap">
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/07-megaworld/img01.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/07-megaworld/img02.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/07-megaworld/img03.jpg" alt="Federal Concept Projects">
                </div>
                 <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/07-megaworld/img04.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/07-megaworld/img05.jpg" alt="Federal Concept Projects">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-6/12 md:w-3/12 ml-0 mr-0 pl-0 pr-0">
          <img id="modal08" class="w-full border-b-4 border-r-4 border-white" onclick="openModal('modal08')" src="/images/federal-projects/08-nlex/img01.jpg" alt="Federal Concept Projects">
          <div id="modal08" class="modal px-3">
            <div class="modalContent px-6 py-6 md:px-8 md:py-8">
              <span class="modalClose">×</span>
              <div class="sub-title text-blue-700 pb-6">
                NLEX CORPORATION (SAN MIGUEL CORPORATION)
              </div>
              <div class="w-full flex flex-wrap">
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/08-nlex/img01.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/08-nlex/img02.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/08-nlex/img03.jpg" alt="Federal Concept Projects">
                </div>
                 <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/08-nlex/img04.jpg" alt="Federal Concept Projects">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-6/12 md:w-3/12 ml-0 mr-0 pl-0 pr-0">
          <img id="modal09" class="w-full border-b-4 border-r-4 border-white" onclick="openModal('modal09')" src="/images/federal-projects/09-capital-common/img01.jpg" alt="Federal Concept Projects">
          <div id="modal09" class="modal px-3">
            <div class="modalContent px-6 py-6 md:px-8 md:py-8">
              <span class="modalClose">×</span>
              <div class="sub-title text-blue-700 pb-6">
                ORTIGAS & COMPANY (CAPITOL COMMONS)
              </div>
              <div class="w-full flex flex-wrap">
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/09-capital-common/img01.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/09-capital-common/img02.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="" src="/images/federal-projects/09-capital-common/img03.jpg" alt="Federal Concept Projects">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-6/12 md:w-3/12 ml-0 mr-0 pl-0 pr-0">
          <img id="modal10" class="w-full border-b-4 border-r-4 border-white" onclick="openModal('modal10')" src="/images/federal-projects/10-pacaran/img06.jpg" alt="Federal Concept Projects">
          <div id="modal10" class="modal px-3">
            <div class="modalContent px-6 py-6 md:px-8 md:py-8">
              <span class="modalClose">×</span>
              <div class="sub-title text-blue-700 pb-6">
                PACARAN ENGINEERING BULACAN
              </div>
              <div class="w-full flex flex-wrap">
                <div class="w-full md:w-6/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/10-pacaran/img01.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-6/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/10-pacaran/img02.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/10-pacaran/img03.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/10-pacaran/img04.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/10-pacaran/img05.jpg" alt="Federal Concept Projects">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-6/12 md:w-3/12 ml-0 mr-0 pl-0 pr-0">
          <img id="modal11" class="w-full border-b-4 border-r-4 border-white" onclick="openModal('modal11')" src="/images/federal-projects/11-pao/img05.jpg" alt="Federal Concept Projects">
          <div id="modal11" class="modal px-3">
            <div class="modalContent px-6 py-6 md:px-8 md:py-8">
              <span class="modalClose">×</span>
              <div class="sub-title text-blue-700 pb-6">
                PAO OFFICE PASIG CITY
              </div>
              <div class="w-full flex flex-wrap">
                <div class="w-full md:w-6/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/11-pao/img01.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-6/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/11-pao/img02.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-6/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/11-pao/img03.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-6/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/11-pao/img04.jpg" alt="Federal Concept Projects">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-6/12 md:w-3/12 ml-0 mr-0 pl-0 pr-0">
          <img id="modal12" class="w-full border-b-4 border-r-4 border-white" onclick="openModal('modal12')" src="/images/federal-projects/12-coc/img01.jpg" alt="Federal Concept Projects">
          <div id="modal12" class="modal px-3">
            <div class="modalContent px-6 py-6 md:px-8 md:py-8">
              <span class="modalClose">×</span>
              <div class="sub-title text-blue-700 pb-6">
                PHILIPPINE CHAMBER OF COMMERCE AND INDUSTRY
              </div>
              <div class="w-full flex flex-wrap">
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/12-coc/img01.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/12-coc/img02.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/12-coc/img03.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/12-coc/img04.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/12-coc/img05.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/12-coc/img06.jpg" alt="Federal Concept Projects">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-6/12 md:w-3/12 ml-0 mr-0 pl-0 pr-0">
          <img id="modal13" class="w-full border-b-4 border-r-4 border-white" onclick="openModal('modal13')" src="/images/federal-projects/13-pom/img02.jpg" alt="Federal Concept Projects">
          <div id="modal13" class="modal px-3">
            <div class="modalContent px-6 py-6 md:px-8 md:py-8">
              <span class="modalClose">×</span>
              <div class="sub-title text-blue-700 pb-6">
                PHILIPPINE POSTAL OFFICE MANILA
              </div>
              <div class="w-full flex flex-wrap">
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/13-pom/img01.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/13-pom/img03.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/13-pom/img04.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/13-pom/img05.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/13-pom/img06.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/13-pom/img07.jpg" alt="Federal Concept Projects">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-6/12 md:w-3/12 ml-0 mr-0 pl-0 pr-0">
          <img id="modal14" class="w-full border-b-4 border-r-4 border-white" onclick="openModal('modal14')" src="/images/federal-projects/14-skyfrieght/img05.jpg" alt="Federal Concept Projects">
          <div id="modal14" class="modal px-3">
            <div class="modalContent px-6 py-6 md:px-8 md:py-8">
              <span class="modalClose">×</span>
              <div class="sub-title text-blue-700 pb-6">
                SKYFRIEGHT - EMO TRANS
              </div>
              <div class="w-full flex flex-wrap">
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/14-skyfrieght/img01.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/14-skyfrieght/img02.jpg" alt="Federal Concept Projects">
                </div>
                <div class="w-full md:w-4/12 py-3 px-3">
                  <img class="m-auto" src="/images/federal-projects/14-skyfrieght/img03.jpg" alt="Federal Concept Projects">
                </div>
              </div>
            </div>
          </div>
        </div>


          
      </div>
      <div id="about" class=""></div>
    </div>
    
    <div class="w-full common-bg-blue section">
      <div class="container">
        <div class="section-title text-white text-center pb-8">
          ABOUT FEDERAL CONCEPTS
        </div>
        <div class="w-full lg:flex">
          <div class="w-full lg:w-6/12 text-common text-white text-justify leading-relaxed lg:pr-6">
              FEDERAL CONCEPTS, INC. started in Carpet and Furniture by  partnership of Francis Ernacio and his wife Roberta Ernacio she works as Sales Supervisor for more than 10 years in Furniture company.
              Francis was a former Assistant VP for Operations in a business consulting company and was the one who came up with the idea to put up their own carpet business. Roberta on the other hand, led this move as she herself worked as a Sales Supervisor in a carpet company for more than 10 years.
              <br><br>
              Federal Concepts’ mission is to provide state of the art carpets, blinds, and furniture with the quality that exceeds all our customer’s expectations.
          </div>
          <div class="w-full lg:w-6/12 text-common text-white text-justify leading-relaxed lg:pl-6">
              FEDERAL CONCEPTS, INC. provides stylish, affordable and customized carpets, roller blinds and furniture suited for office, homes, establishments and hotels. We pride ourselves on creating an outstanding experience for each and every one of our clients.
              <br><br>
              The carpet world can be confusing and there are plenty of options to choose from. We’re happy to help you decide which type of carpet flooring is best for your home, business, style, and lifestyle.
              <br><br>
              We do carpet planning and fitting services brought straight to the comfort of your home or office.
          </div>
        </div>
      </div>
    </div>

    <div class="w-full common-bg-blue02">
      <div class="container">
        <div class="w-full section md:flex md:flex-wrap">
          <div class="w-full md:w-6/12 flex flex-wrap">
            <div class="w-6/12 text-center mb-8 lg:mb-0">
              <div class="w-full px-8 lg:px-0">
                <img width="180" class="rounded-full shadow-xl m-auto" src="/images/icon-quality.png">
              </div>
              <div class="sub-title text-white pt-5">Quality Materials</div>
            </div>
            <div class="w-6/12 text-center mb-8 lg:mb-0">
              <div class="w-full px-8 lg:px-0">
                <img width="180" class="rounded-full shadow-xl m-auto" src="/images/icon-fast.png">
              </div>
              <div class="sub-title text-white pt-5">Fast Project<br>Timeline</div>
            </div>
          </div>
          <div class="w-full md:w-6/12 flex flex-wrap">
            <div class="w-6/12 text-center mb-8 lg:mb-0">
              <div class="w-full px-8 lg:px-0">
                <img width="180" class="rounded-full shadow-xl m-auto" src="/images/icon-project.png">
              </div>
              <div class="sub-title text-white pt-5">Project Planning<br>and Goal</div>
            </div>
            <div class="w-6/12 text-center">
              <div class="w-full px-8 lg:px-0">
                <img width="180" class="rounded-full shadow-xl m-auto" src="/images/icon-effeciency.png">
              </div>
              <div class="sub-title text-white pt-5">Efficiency</div>
            </div>
          </div>
        </div>
      </div>
    </div>  

    <div class="w-full common-bg-blue section">
      <div class="container">
        <div class="section-title text-white text-center mb-8">
          OUR CLIENTS
        </div>
        <!-- <div class="yellow-border mb-12 m-auto"></div> -->
        <div class="w-full">
          <no-ssr> <!-- important to add no-ssr-->
            <carousel
              class="w-full" :autoHeight="true" :autoplay="true" :autoplayTimeout="5000" :dots="true" :loop="true" :nav="false" :rewind="true" :responsive="{
                0: {
                  items: 2,
                  margin: 20
                },
                768: {
                  items: 4,
                  margin: 30
                }
              }"
              >
              <div class="w-full" v-for="(client, i) in clients" :key="i">
                <img class="m-auto" :src="client.file" :alt="client.prod" />
              </div>
            </carousel>
          </no-ssr>
        </div>
      </div>
    </div>

    <div class="w-full contact-bg section">
      <div class="container">
          <div class="w-full text-center">
            <div class="w-full mb-8">
              <div class="sub-title font-bold text-white uppercase mb-2">Office / Store Address</div>
              <div class="text-common text-white mb-4">
                  Unit 101 LCC Bldg. 38D Mercedes Ave., Barangay. San Miguel Pasig City. (showroom)
              </div>
            </div>
            <div class="w-full mb-8">
              <div class="sub-title font-bold text-white uppercase mb-2">Contact Number</div>
              <div class="text-common text-white">
                <a class="tel-footer" href="tel:09151471977">09151471977 (Globe)</a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a class="tel-footer" href="tel:09226850003">09226850003 (Sun)</a>
              </div>
              <div class="sub-title font-bold text-white uppercase mb-2 mt-8">Landline Number</div>
              <div class="text-common text-white">
                <a class="tel-footer" href="tel:0277993089">(02) 7799 3089</a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a class="tel-footer" href="tel:0286521240">(02) 8652 1240</a>
              </div>
            </div>
            <div class="w-full">
              <div class="sub-title font-bold text-white uppercase mb-2">E-MAIL ADDRESS</div>
              <div class="w-full md:flex md:justify-center text-common text-white">
                <a class="tel-footer" href="mailto:federalsalesinquiry@gmail.com" target="_blank">federalsalesinquiry@gmail.com</a>
                <span class="hidden md:block">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <br class="block md:hidden">
                <a class="tel-footer" href="mailto:info@federalconcepts.ph" target="_blank">info@federalconcepts.ph</a>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div class="footer-wrapper">
      <div class="container text-center border-t-2 border-blue-900 pt-4 pb-6">
        <div class="w-full text-common">Maintained and Developed by AMPH Advertising Agency Inc.</div>
      </div>
    </div>
  </div>

</template>
<script>
import Headernav from '@/components/Headernav.vue'
import contactform from '@/components/contactform.vue'
import carousel from 'vue-owl-carousel'
import vueSmoothScroll from 'vue-smooth-scroll'
import {CollapseTransition} from "vue2-transitions";
export default {
  components: {
    Headernav,
    contactform,
    vueSmoothScroll,
    carousel,
    CollapseTransition
  },
  data () {
    return {
      clients: [
        {file: '/images/clients/clients-37.jpg'}, // Samsung
        {file: '/images/clients/clients-36.jpg'}, // Goldilocks
        {file: '/images/clients/clients-07.jpg'}, // Ortigas and Company
        {file: '/images/clients/clients-13.jpg'}, // Megaworld
        {file: '/images/clients/clients-09.jpg'}, // Capitol Commons
        {file: '/images/clients/clients-11.jpg'}, // Tiendesitas
        {file: '/images/clients/clients-14.jpg'}, // Marriott
        
        {file: '/images/clients/clients-03.jpg'}, // Sky Freight
        {file: '/images/clients/clients-02.jpg'}, // St. Marys College
        {file: '/images/clients/clients-17.jpg'}, // Sheraton
        {file: '/images/clients/clients-18.jpg'}, // Diamond Motor Corporation
        {file: '/images/clients/clients-21.jpg'}, // Century Properties
        {file: '/images/clients/clients-22.jpg'}, // Hotel Luna
        {file: '/images/clients/clients-23.jpg'}, // New Era University
        {file: '/images/clients/clients-24.jpg'}, // Le Monet Hotel
        {file: '/images/clients/clients-25.jpg'}, // National Childrens Hospital
        {file: '/images/clients/clients-30.jpg'}, // Global Dominion
        {file: '/images/clients/clients-32.jpg'}, // Via Mare
        {file: '/images/clients/clients-34.jpg'}, // Arm Forces of the Philippines
        {file: '/images/clients/clients-35.jpg'}, // Bureau of Soil

        {file: '/images/clients/clients-39.jpg'}, // Boskalis
        {file: '/images/clients/clients-40.jpg'}, // egis
        {file: '/images/clients/clients-41.jpg'}, // PNP
        {file: '/images/clients/clients-42.jpg'} // My Brush

      ]
    }
  }
}
</script>
<style>
</style>
