<template>
	<form v-on:submit.prevent="submit">
        <div class="w-full">
            <div class="w-full lg:flex">
                <div class="field-wrapper lg:pr-2">
                    <div class="w-full">
                        <!-- <label for="name" class="contact-label">Name<span style="color: #ff0000 !important;">*</span></label> -->
                        <input type="text" id="name" v-model="fields.name" class="contactform-field" placeholder="Name">
                    </div>
                    <template v-if="errors && errors.name">
                        <p v-text="errors.name" class="text-left bg-blue-200" style="font-size: 12px; color: #ff0000;"></p>
                    </template>
                </div>
                <div class="field-wrapper lg:px-4">
                    <div class="w-full">
                        <!-- <label for="contact" class="contact-label">Contact Number<span style="color: #ff0000 !important;">*</span></label> -->
                        <input type="text" id="contact" v-model="fields.contact" class="contactform-field" placeholder="Phone Number">
                    </div>
                    <template v-if="errors && errors.contact">
                        <p v-text="errors.contact" class="text-left bg-blue-200" style="font-size: 12px; color: #ff0000;"></p>
                    </template>
                </div>
                <div class="field-wrapper lg:pl-2">
                    <div class="w-full">
                        <!-- <label for="email" class="contact-label">Email<span style="color: #ff0000 !important;">*</span></label> -->
                        <input type="email" id="email" v-model="fields.email" class="contactform-field" placeholder="Email">
                    </div>
                    <template v-if="errors && errors.email">
                        <p v-text="errors.email" class="text-left bg-blue-200" style="font-size: 12px; color: #ff0000;"></p>
                    </template>
                </div>
            </div>
            <div class="message-field-wrapper">
                <div class="w-full">
                    <!-- <label for="message" class="contact-label">Message<span style="color: #ff0000 !important;">*</span></label> -->
                    <textarea rows="5" id="message" v-model="fields.message" class="col-12 contactform-field" placeholder="Message"></textarea>
                </div>
                <template v-if="errors && errors.message">
                    <p v-text="errors.message" class="text-left bg-blue-200" style="font-size: 12px; color: #ff0000;"></p>
                </template>
            </div>
            <div class="w-full">
                <button type="submit" class="flex justify-center contactform-submit" v-bind:disabled="busy">
                    <span>Submit</span>
                    <svg v-show="busy" class="contactform-loading w-6 mt-12 mx-auto" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
                                <stop stop-color="#fff" stop-opacity="0" offset="0%" />
                                <stop stop-color="#fff" stop-opacity=".631" offset="63.146%" />
                                <stop stop-color="#fff" offset="100%" />
                            </linearGradient>
                        </defs>
                        <g fill="none" fill-rule="evenodd">
                            <g transform="translate(1 1)">
                                <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
                                    <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite" />
                                </path>
                                <circle fill="#fff" cx="36" cy="18" r="1">
                                    <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite" />
                                </circle>
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import { InputError, hapiMixins } from '@juno0424/vue2-hapi-inputs'

export default {
	mixins: [hapiMixins],
	components: {InputError},
	name: 'ContactUsForm',
	data() {
		return {
			name: 'ContactUsForm',
			endpoint: "https://hapiform.sg/api/5bc978e6-a2ad-4b1a-ad26-d17bee9a5e1e",
			redirectTo: "/thank-you/",
		};
	}
}
</script>